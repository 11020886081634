import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import toastr from "toastr";
import { deleteAdminFarm, getFarmOwnersData, getOfficesData, resetResponseState, unsetLoading,
         setAdminFarmId, setFarmId } from '../../store/appAction';
import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, InputLabel, ListSubheader, 
         MenuItem, Select, Stack, TextField, Tooltip } from '@mui/material';
import CancelModal from '../Modal/CancelModal';
import DeleteModal from '../Modal/DeleteModal';
import { UserRole } from '../../helpers/userHelper';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { EDIT, CREATE } from '../../constants/modes';
import { matchSorter } from 'match-sorter';
import CircularProgress from '@mui/material/CircularProgress';
import {TooltipUserEmail} from '../../components/Tooltips/UserEmail';

const FarmForm = props => {

    const {
        values: {
            id,
            farmName,
            holdingNumber,
            farmOwnerUserId,
            forganisation,
            organisationId,
            foffice,
            officeId,
            addressLine1,
            addressLine2,
            townCity,
            countryId,
            zipPostCode,
            businessReferenceNumber,
            license,
            isUpgraded,
            initialFarmOwner,
            externalFarmId
        },
        errors,
        touched,
        handleSubmit,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue,
        resetForm,
        role,
        mode,
        isMyFarm
    } = props;
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    // farmOwners= null so that we can distinguish when we get empty data
    const [farmOwners, setFarmOwners] = useState(null);
    const [organisations, setOrganisations] = useState([]);
    const [offices, setOffices] = useState([]);

    const [farmOwner, setFarmOwner] = useState(null);
    const [organisation, setOrganisation] = useState(null);
    const [office, setOffice] = useState(null);
    const [labelForBRN, setLabelForBRN] = useState('Business Reference Number (BRN)');

    

    const countries = useSelector(state => state.common.countries);

    const currentFarmOwners = useSelector(state => state.common.farmOwners);
    const currentOrganisations = useSelector(state => state.common.organisations);
    const currentOffices = useSelector(state => state.common.offices);

    const { successMsg, errorMsg } = useSelector(state => state.common);

    const filterOptionsForOrganization = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'organizationName']});
    const filterOptionsForFarmOwner = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'name']});
    const filterOptionsForOffice = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'officeName']});

    useEffect(() => {
        organisationId && dispatch(getOfficesData({ organisationId }));
        dispatch(getFarmOwnersData({ farmId: id }));
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(getFarmOwnersData({ farmId: id }));
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                if (mode === CREATE) {
                    handleReset();
                    setFarmOwner(null);
                    setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
                    setOffice(null);
                }
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        // When EDITing we set the farm owner within the list in case it's being changed.
        // CREATEing doesn't set anything
        (mode === EDIT) && (currentFarmOwners?.length > 0) &&
            farmOwnerUserId && setFarmOwner(_.find(currentFarmOwners, { id: farmOwnerUserId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setFarmOwners(currentFarmOwners)
    }, [currentFarmOwners]);

    useEffect(() => {
        setOrganisations(currentOrganisations);
        // When EDITing we set the organistation within the list in case it's being changed.
        // CREATEing doesn't set anything
        (mode === EDIT) && (currentOrganisations?.length > 0) &&
            organisationId && setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisations]);

    useEffect(() => {
        setOffices(currentOffices);
        // When EDITing we set the office within the list in case it's being changed.
        // CREATEing doesn't set anything
        (mode === EDIT) && (currentOffices?.length > 0) &&
            officeId && setOffice(_.find(currentOffices, { id: officeId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOffices]);

    useEffect(() => {
        setFarmOwner(_.find(currentFarmOwners, { id: farmOwnerUserId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [farmOwnerUserId]);

    useEffect(() => {
        setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisations]);

    useEffect(() => {
        setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        if (organisationId)
            dispatch(getOfficesData({ organisationId }))
        else {
            setFieldValue("officeId", "");
            setOffices([]);
            setOffice(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId]);

    useEffect(() => {
        setOffice(_.find(currentOffices, { id: officeId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeId]);

    useEffect(() => {
        const selectedCountry = countries?.find( ({ id }) => id === countryId);
        switch(selectedCountry?.name){
            case 'England' : setLabelForBRN('Single Business Identifier (SBI)'); break;
            case 'Wales' : setLabelForBRN('Customer Reference Number (CRN)'); break;
            case 'N Ireland' : setLabelForBRN('Business IDentifier (BID)'); break;
            default : setLabelForBRN('Business Reference Number (BRN)'); break;
        }
    }, [countryId,countries]);

    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            window.scrollY = 0;
            handleReset();
            if (mode !== EDIT) {
                setFarmOwner(farmOwnerUserId);
                setOrganisation(null);
                setOffice(null);
            }
        }
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            window.scrollY = 0;
            handleReset();
            setFarmOwner(null);
            setOrganisation(null);
            setOffice(null);
            dispatch(deleteAdminFarm());
            dispatch(setAdminFarmId(null));
            dispatch(setFarmId(null));
            handleBackResponse();
        }
    }

    const handleBackResponse = () => {
        navigate('/admin/farm/list');
    }

    const fetchOptions = (data, nameKey) => {
        return (
            Object.entries(data || {}).map(([optionId, optionData]) => (
                <MenuItem
                    id={optionData.id}
                    key={optionData.key}
                    value={optionData.id}
                >
                    {optionData[nameKey]}
                </MenuItem>
            )));
    }

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth autoFocus
                        id="farmName" name="farmName" label="Farm Name"
                        error={Boolean(touched.farmName ? errors.farmName : false)}
                        onChange={handleChange}  value={farmName}
                        helperText={touched.farmName ? errors.farmName : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                { (isMyFarm || !farmOwners || UserRole.isFarmer(role) ) ?
                    <Grid item xs={12} sm={6} container alignItems="center">
                        <TextField fullWidth label="Farm Owner" value={initialFarmOwner}
                        InputProps={isMyFarm?{}:{endAdornment: (
                            <Box sx={{ display: 'flex' }}><CircularProgress size="1.5rem" /></Box>)}}
                        />
                    </Grid>                
                    :
                    <Grid item xs={12} sm={6}>
                        { !UserRole.isFarmer(role) &&
                            <Autocomplete id="farmOwner-autocomplete" disablePortal
                                value={farmOwner || {id:farmOwnerUserId,name:initialFarmOwner}} 
                                options={farmOwners?.length?farmOwners:[{id:farmOwnerUserId,name:farmOwner}]} 
                                getOptionLabel={(owner) => `${owner?.name}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                filterOptions={filterOptionsForFarmOwner} 
                                disabled={(mode === EDIT && isUpgraded === false)|| farmOwners?.length<1}
                                renderOption={(props, option) => {
                                    return (
                                        <TooltipUserEmail userid={option.id} followCursor > 
                                            <li {...props} key={option.id}>
                                            {option.name}
                                            </li>
                                        </TooltipUserEmail>
                                    );
                                }}
                                onChange={(e, value) => {
                                    setFieldValue("farmOwnerUserId", value?.id || "");
                                }}
                                onOpen={handleBlur}
                                renderInput={(params) => (
                                    <TooltipUserEmail userid={farmOwnerUserId} followCursor > 
                                    <TextField  {...params} fullWidth id="farmOwnerUserId" name="farmOwnerUserId"
                                        label="Farm Owner" error={Boolean(touched.farmOwnerUserId ? errors.farmOwnerUserId : false)}
                                        value={farmOwnerUserId} helperText={touched.farmOwnerUserId ? errors.farmOwnerUserId : ""}
                                    />
                                    </TooltipUserEmail>
                                )}
                            />
                        }
                    </Grid>
                }
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="holdingNumber"
                        name="holdingNumber"
                        label="Holding Number"
                        onChange={handleChange}
                        error={Boolean(touched.holdingNumber ? errors.holdingNumber : false)}
                        value={holdingNumber}
                        helperText={touched.holdingNumber ? errors.holdingNumber : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="businessReferenceNumber"
                        name="businessReferenceNumber"
                        error={Boolean(touched.businessReferenceNumber ? errors.businessReferenceNumber : false)}
                        label={labelForBRN}
                        onChange={handleChange}
                        value={businessReferenceNumber}
                        helperText={touched.businessReferenceNumber ? `${labelForBRN} is required` : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {UserRole.isConsultantOrAdmin(role) &&
                        <Autocomplete
                            id="organisation-autocomplete"
                            disablePortal
                            value={organisation || {id:organisationId,organizationName:forganisation}}
                            options={organisations.length?organisations:[{id:organisationId,organizationName:forganisation}]}
                            getOptionLabel={(org) => `${org?.organizationName}`}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            disabled={ (mode === EDIT && isUpgraded === false) || organisations?.length<1 }
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {option.organizationName}
                                    </li>
                                );
                            }}
                            filterOptions={filterOptionsForOrganization}
                            onChange={(e, value) => {
                                setFieldValue("organisationId", value?.id || "");
                                setFieldValue("officeId", "");
                                setOffices([]);
                                setOffice(null);
                            }}
                            onOpen={handleBlur}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="organisationId"
                                    name="organisationId"
                                    label="Organisation"
                                    error={Boolean(touched.organisationId ? errors.organisationId : false)}
                                    value={organisationId}
                                    helperText={touched.organisationId ? errors.organisationId : ""}
                                />
                            )}
                        />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        UserRole.isConsultantOrAdmin(role) &&
                        <Autocomplete
                            id="office-autocomplete"
                            disablePortal
                            value={office || {id:officeId,officeName:(foffice||'')}}
                            options={offices.length?offices:[{id:officeId,officeName:(foffice ||'')}]}
                            getOptionLabel={(office) => `${office?.officeName}`}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            filterOptions={filterOptionsForOffice}
                            disabled={ (mode === EDIT && isUpgraded === false) || offices?.length<1}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        {option.officeName}
                                    </li>
                                );
                            }}
                            onChange={(e, value) => {
                                setFieldValue("officeId", value?.id || "");
                            }}
                            onOpen={handleBlur}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="officeId"
                                    name="officeId"
                                    label="Office"
                                    error={Boolean(touched.officeId ? errors.officeId : false)}
                                    value={officeId}
                                    helperText={touched.officeId ? errors.officeId : ""}
                                />
                            )}
                        />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="addressLine1"
                        name="addressLine1"
                        error={Boolean(touched.addressLine1 ? errors.addressLine1 : false)}
                        label="Address 1"
                        onChange={handleChange}
                        value={addressLine1}
                        helperText={touched.addressLine1 ? errors.addressLine1 : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="addressLine2"
                        name="addressLine2"
                        error={Boolean(touched.addressLine2 ? errors.addressLine2 : false)}
                        label="Address 2"
                        onChange={handleChange}
                        value={addressLine2}
                        helperText={touched.addressLine2 ? errors.addressLine2 : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="townCity"
                        name="townCity"
                        error={Boolean(touched.townCity ? errors.townCity : false)}
                        label="Town/City"
                        onChange={handleChange}
                        value={townCity}
                        helperText={touched.townCity ? errors.townCity : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl
                        fullWidth
                        error={Boolean(touched.countryId ? errors.countryId : false)}
                    >
                        <InputLabel id="countryId-select">Country</InputLabel>
                        <Select
                            fullWidth
                            name="countryId"
                            label="Country"
                            id="countryId"
                            labelId="countryId-select"
                            value={countryId}
                            onChange={handleChange}
                            disabled={mode === EDIT && isUpgraded === false}
                        >
                            <ListSubheader disableSticky={true} sx={{ pl: 2 }}>UK</ListSubheader>
                            {(countries ? countries : []).map((option) => (
                                (option.countryCategory === 1) && <MenuItem
                                    id={option.id}
                                    key={option.id}
                                    value={option.id}
                                    sx={{ pl: 3 }}
                                >
                                    - {option.name}
                                </MenuItem>
                            ))}
                            <ListSubheader disableSticky={true} sx={{ pl: 2 }}>----</ListSubheader>
                            {(countries ? countries : []).map((option) => (
                                (option.countryCategory !== 1) && <MenuItem
                                    id={option.id}
                                    key={option.id}
                                    value={option.id}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched.countryId && <FormHelperText>{errors.countryId}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        id="zipPostCode"
                        name="zipPostCode"
                        error={Boolean(touched.zipPostCode ? errors.zipPostCode : false)}
                        label="Zip or Postcode"
                        onChange={handleChange}
                        value={zipPostCode}
                        helperText={touched.zipPostCode ? errors.zipPostCode : ""}
                        disabled={mode === EDIT && isUpgraded === false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {UserRole.isConsultantOrAdmin(role) &&
                        <FormControl fullWidth error={Boolean(touched.organizationId ? errors.organizationId : false)}>
                            <InputLabel id="license-select">Licence{/* UK English */}</InputLabel>
                            <Select
                                disabled={!UserRole.isSuperAdmin(role) || (mode === EDIT && isUpgraded === false)}
                                fullWidth
                                name="license"
                                label="Licence"/* UK English */
                                id="license"
                                labelId="license-select"
                                onChange={handleChange}
                                defaultValue={'Paid'}//Should replaced by subscription module.
                                value={license}
                                helperText={touched.license ? errors.license : ""}
                            >
                                {/* Should updated by subscription module. */}
                                {fetchOptions([{id:0,key:'Free',license:'Free'},
                                               {id:1,key:'Paid',license:'Paid'}], 'license')}
                            </Select>
                        </FormControl>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip title='Enter your own reference to identify this farm ' placement="top" >
                        <TextField
                            fullWidth
                            id="externalFarmId"
                            name="externalFarmId"
                            error={Boolean(touched.externalFarmId ? errors.externalFarmId : false)}
                            label="External Farm ID"
                            onChange={handleChange}
                            value={externalFarmId}
                            helperText={touched.externalFarmId ? errors.externalFarmId : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>
                <Stack spacing={2} sx={{ marginLeft: 'auto', marginTop: 3 }} direction="row" >
                    <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                        onClick={handleBackResponse}>
                        Back
                    </Button>
                    <Button variant="outlined" sx={{ textTransform: 'none' }} disabled={mode === EDIT && isUpgraded === false}
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalOpen(true);
                        }}>
                        Cancel
                    </Button>
                    {(mode === EDIT) && UserRole.isAllAdmins(role) &&
                        <Button variant="outlined" sx={{ textTransform: 'none' }} disabled={mode === EDIT && isUpgraded === false}
                            onClick={(e) => {
                                e.stopPropagation();
                                setDeleteModalOpen(true);
                            }}>
                            Delete
                        </Button>}
                    <Button type="submit" variant="contained" sx={{ textTransform: 'none' }} disabled={mode === EDIT && isUpgraded === false}>
                        Save
                    </Button>
                </Stack>
            </Grid>
            <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
            <DeleteModal isOpen={deleteModalOpen} handleResponse={handleDeleteModalResponse}
                value='If you delete you will lose all farm data. Are you sure you want to delete?' />
        </Box>
    )
}

FarmForm.propTypes = {
    values: PropTypes.object,/*
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    handleReset,
    setFieldValue,
    resetForm,
    role,
    mode,
    isMyFarm*/
};

export default FarmForm