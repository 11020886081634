import { isNumber, isFinite } from "lodash";

export const addCommas = (value, ignoreZero=true, decimals=2) => {
    // return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/CO2/, "CO₂");
    if(ignoreZero && value===0){
        return 0
    }else if(isFinite(value) && !Number.isInteger(value)){
        value=value.toFixed(decimals)
    }
    return value?.toString().replace(/^[+-]?\d+/, (int) => {
        return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }).replace(/CO2/, "CO₂");
}

export const m3Superscript = (value) => {
    return value?.replace(/m3/, "m³");
}

export const removeCommas = (value) => {
    return isNumber(value) ? value : value?.replace(/,/g, "");
}

export const replaceEmptyStringWithNull = (obj) =>  {
    if (typeof obj !== 'object' || obj === null) {
        if (obj === '') {
            return null;
        }
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(item => replaceEmptyStringWithNull(item));
    }
    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = replaceEmptyStringWithNull(obj[key]);
        }
    }
    return newObj;
  }

  export const stripNonPrintable = (text, stripCtrlFmtSurr=true, trim=true)=> {
    // strip control chars. optionally, keep surrogates and formats
    if(stripCtrlFmtSurr) {
      text = text.replace(/\p{C}/gu, '');
    } else {
      text = text.replace(/\p{Cc}/gu, '');
      text = text.replace(/\p{Co}/gu, '');
      text = text.replace(/\p{Cn}/gu, '');
    }
    // strip certain symbols
    text = text.replace(/["'`\\/~#%^<>¬*=+]/g, '');
    // strip newlines
    text = text.replace(/\r/g, '');
    text = text.replace(/\n/g, '');
    text = text.replace(/\p{Zl}/gu, '');
    text = text.replace(/\p{Zp}/gu, '');

    // normalize spaces
    text = text.replace(/\p{Zs}/gu, ' ');
    text = text.replace(/\t/gu, ' ');
    // trim
    if(trim){
        text = text.replace(/^\s+/gu, '');
        text = text.replace(/\s+$/gu, '');    
    }

    return text;
}
