import {isObjectNotArray} from './genericHelper'

export const AGCPermissions = Object.freeze({
    Farms : "AgreCalc.Farms",
    FarmsCreate : "AgreCalc.Farms.Create",
    FarmsRead : "AgreCalc.Farms.Read",
    FarmsUpdate : "AgreCalc.Farms.Update",
    FarmsDelete : "AgreCalc.Farms.Delete",

    Users : "AgreCalc.Users",
    UsersCreate : "AgreCalc.Users.Create",
    UsersRead : "AgreCalc.Users.Read",
    UsersUpdate : "AgreCalc.Users.Update",
    UsersDelete : "AgreCalc.Users.Delete",

    Reports : "AgreCalc.Reports",
    ReportsCreate : "AgreCalc.Reports.Create",
    ReportsRead : "AgreCalc.Reports.Read",
    ReportsUpdate : "AgreCalc.Reports.Update",
    ReportsDelete : "AgreCalc.Reports.Delete",
    ReportsCopy : "AgreCalc.Reports.Copy",
    ReportsSubmitForReview : "AgreCalc.Reports.SubmitForReview",
    ReportsExportPDF : "AgreCalc.Reports.ExportPDF",
    ReportsExportCSV : "AgreCalc.Reports.ExportCSV",

    Benchmarks : "AgreCalc.Benchmarks",
    BenchmarksRead : "AgreCalc.Benchmarks.Read"
});

export const AGCReportsPermissions = Object.freeze({
    ReportsEdit : [AGCPermissions.Reports, AGCPermissions.ReportsRead, AGCPermissions.ReportsUpdate],
    ReportsNew : [AGCPermissions.Reports, AGCPermissions.ReportsCreate],
    ReportsSubmit : [AGCPermissions.Reports, AGCPermissions.ReportsSubmitForReview],
    ReportsCanCopy : [AGCPermissions.Reports, AGCPermissions.ReportsCopy],

    //Internal function to allow checks for all entries
    canDo: (permissions, checkAgainst)=>{
        if(isObjectNotArray(permissions)){
            return Object.values(permissions ?? {}).some(value =>AGCReportsPermissions.canDo(value,checkAgainst))
        }else{
            return (checkAgainst.includes(permissions))
        }
    },
    canEdit : (permissions) => { return AGCReportsPermissions.canDo(permissions,AGCReportsPermissions.ReportsEdit) },
    canCopy : (permissions) => { return AGCReportsPermissions.canDo(permissions,AGCReportsPermissions.ReportsCanCopy) },
    canCreate : (permissions) => { return AGCReportsPermissions.canDo(permissions,AGCReportsPermissions.ReportsNew) },
    canSubmit : (permissions) => { return AGCReportsPermissions.canDo(permissions,AGCReportsPermissions.ReportsSubmit) },
});
