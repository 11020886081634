import _ from 'lodash';

// Checks if all properties of an object are undefined or null
export const isObjectEmpty = (o) =>{
    return !_.values(o).some(x => x !== undefined && x!==null); 
} 

/**
 *  Check if obj is an object but not an array
 * @param {*} obj 
 * @returns true if object
 */
export const isObjectNotArray = (obj)=> {
    return _.isObject(obj) && !Array.isArray(obj)
}